import React from 'react';
import ProductPageBase from "../../components/ProductPage";

const ProductPage = (props) => {
    return (
        <ProductPageBase productCode={"P10"} />
    )
};

export default ProductPage;
